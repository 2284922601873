import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

function Copyright() {
  return (
    <Typography variant="body2" color="#AFACAC">
      {"Copyright © "}
      <Link color="#AFACAC">Haroug Group</Link> {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Footer() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#27272C",
        alignItems: "center",
        paddingTop: 2,
        paddingBottom: 2,
      }}
    >
      <Copyright />
    </Box>
  );
}
