import {
  Box,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Service from "./Components/Service/Service";
import { services as servicesList } from "./constant";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const NextArrow = (props) => {
  return <h1></h1>;
};
const Services = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: isSmallScreen ? 1 : 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0",
    variableWidth: false,
    nextArrow: <NextArrow />,
  };
  return (
    <Box
      style={{
        paddingTop: 20,
      }}
      sx={{
        paddingBottom: { xs: 2, sm: 10 },
        paddingLeft: { xs: 3, sm: 5 },
        margin: { xs: 2, sm: 7 },
      }}
    >
      <Divider
        style={{
          backgroundColor: "#27272C",
          width: 55,
          padding: 1,
          marginTop: 20,
          marginBottom: 20,
        }}
      />
      <Typography
        style={{
          color: "#27272C",
          fontFamily: "Montserrat",
          fontWeight: 700,
          fontSize: "30px",
          paddingBottom: 25,
        }}
      >
        Our Services
      </Typography>
      <Typography
        style={{
          color: "#00000099",
          fontFamily: "Montserrat",
          fontWeight: 500,
          fontSize: "20px",
        }}
      >
        We offer our clients exceptional service, seamless transitions through
        project phases, and deploy the right people for the job—all at highly
        competitive rates
      </Typography>

      <div style={{ overflow: "hidden", marginTop: isSmallScreen ? 20 : 50 }}>
        <Slider {...settings}>
          {servicesList.map((service, index) => (
            <div key={index}>
              <Service service={service} isSmallScreen={isSmallScreen} />
            </div>
          ))}
        </Slider>
      </div>
    </Box>
  );
};
export default Services;
