import service1 from "../../../../assets/images/Services/service1.jpg";
import service2 from "../../../../assets/images/Services/service2.jpg";
import service3 from "../../../../assets/images/Services/service3.jpg";
import service4 from "../../../../assets/images/Services/service4.png";
import service5 from "../../../../assets/images/Services/service5.jpg";
import service6 from "../../../../assets/images/Services/service6.jpg";
import service7 from "../../../../assets/images/Services/service7.jpg";
import service8 from "../../../../assets/images/Services/service8.jpg";
import service9 from "../../../../assets/images/Services/service9.jpg";
import service10 from "../../../../assets/images/Services/service10.jpg";

import service1Mob from "../../../../assets/images/Services/service1Mob.jpg";
import service2Mob from "../../../../assets/images/Services/service2Mob.jpg";
import service3Mob from "../../../../assets/images/Services/service3Mob.jpg";
import service4Mob from "../../../../assets/images/Services/service4Mob.png";
import service5Mob from "../../../../assets/images/Services/service5Mob.jpg";
import service6Mob from "../../../../assets/images/Services/service6Mob.jpg";
import service7Mob from "../../../../assets/images/Services/service7Mob.jpg";
import service8Mob from "../../../../assets/images/Services/service8Mob.jpg";
import service9Mob from "../../../../assets/images/Services/service9Mob.jpg";
import service10Mob from "../../../../assets/images/Services/service10Mob.jpg";

export const services = [
  {
    title: "Oil Field and Specialist Equipment ",
    description:
      "Site preparation for a new location is always challenging, especially the setup of a new camp. Establishing access roads and placing ancillary equipment, from retention and recirculation pits to mud tanks, is a crucial component of the overall location setup.The company’s experience, expertise, and engineers will alleviate the challenges of these tasks, allowing you to focus on rigging up. ",
    description2: `
      Our services cover everything from freshwater storage to wastewater removal and reclamation, including solid waste management systems. Additionally, we offer equipment rental, from welders to D11 Cats.Al Harouge takes great pride in its excellent inventory and its ability to procure a vast range of specialist equipment with a short lead-time.`,
    forHome:
      "Our services cover everything from freshwater storage to wastewater removal and reclamation, including solid waste management systems. Additionally, we offer equipment rental, from welders to D11 Cats.",
    image: service1,
    mobImage: service1Mob,
  },
  {
    title: "Logistics and Transportation",
    description:
      "Logistics plays a key role in the oil and gas industry. Al Harouge offers multi-purpose warehousing facilities along with cargo handling by land, sea, or air. By integrating our logistics services, including warehousing and transportation solutions, the company enables clients to experience a seamless service that is reliable, economical, and hassle-free at the same time.The transportation of equipment and rigs represents some of the most complicated tasks in the oil and gas industry. All related tasks require an enormous amount of planning, safety assessments, and applications.",
    description2: `
      Our team of transportation experts has the necessary knowledge and experience to ensure that Al Harouge provides clients with flawless, risk-free transportation services at all times.`,
    image: service2,
    mobImage: service2Mob,
  },
  {
    title: "Power Generation",
    description:
      "In the oil and gas industry, as well as in other industrial applications, power is generated by electric generators using gas turbines, steam turbines, or reciprocating engines as prime movers. Turbo expanders are also employed for power generation, where pressurized gas is expanded for process reasons and subsequently made available for power recovery.",
    forHome:
      "In the oil and gas industry and other industrial settings, power is generated using electric generators powered by gas turbines, steam turbines, or reciprocating engines. Turbo expanders are also utilized for power generation, expanding pressurized gas for process purposes and recovering power.",
    image: service3,
    mobImage: service3Mob,
  },
  {
    title: "Engineering Service",
    description: `Our engineering and technical staff have excellent and extensive experience in the oil and gas field industry.

    Our personnel specialize in:
    • Project management
    • Project controls
    • Field layout 
    • Mechanical-Electrical engineering 
    • Facilities engineering 
    • Pipeline engineering 
    • QAQC management 
    • Discipline inspection service 
    • Construction, commissioning, and start-up management 
    • Operations, production, and maintenance`,
    forHome:
      "Our adept team, experienced in oil and gas, excels in project management, diverse engineering disciplines, QAQC management, and provides comprehensive support from construction to ongoing operations and maintenance.",
    image: service4,
    mobImage: service4Mob,
  },
  {
    title: "Catering and Camp Maintenance ",
    description: `Al Harouge offers comprehensive catering solutions, encompassing bulk food supply, logistics, and the setup of canteens, kitchen facilities, and storage units. With some of the finest chefs in the region and skilled catering staff, we curate diverse menus catering to a variety of palates. Our catering facilities operate around the clock and across the region.`,
    description2: `
      Camp maintenance constitutes the second leg of our on-site services, providing convenience and cost-effectiveness for our clients. From laundry services to cleaning and domestic waste disposal, Al Harouge sets a new industry benchmark for convenience in camp services.`,
    image: service5,
    mobImage: service5Mob,
  },
  {
    title: "Pipelines",
    description: `Al Harouge's capability to operate under pressure in both operations and product delivery is at the core of our business. We understand our clients' expectations regarding service quality, and the standards we set are unsurpassed in the industry.`,
    description2: `
      We offer the most comprehensive services, including bespoke solutions and full project management. We provide specialist products and service repair solutions on time, anywhere, anytime.`,
    image: service6,
    mobImage: service6Mob,
  },
  {
    title: "Pipe Recovery",
    description: `Al Harouge provides the industry with highly trained personnel and specialized equipment designed, manufactured, and tested by our engineers.`,
    description2: `
      Our service locations feature the latest sophisticated technologies for handling well site pipe recovery operations in a safe, efficient, and cost-effective manner, both onshore and offshore.`,
    image: service7,
    mobImage: service7Mob,
  },
  {
    title: "Fracturing Technologies",
    description: `Al Harouge has the people, technology, and methodology to deliver a faster, more responsive, and on-time fracturing service.
    
    `,
    description2:
      "With our team of chemists, engineers, and service technicians, the company creates solutions to complete jobs safely and efficiently. Equipped with the latest cutting-edge technology and state-of-the-art equipment, and with a focus on service excellence, the synergy of the total solutions package provided by Al Harouge is comprehensive and second to none.",
    forHome: `
      Al Harouge has the people, technology, and methodology to deliver a faster, more responsive, and on-time fracturing service.With our team of chemists, engineers, and service technicians, the company creates solutions to complete jobs safely and efficiently.`,
    image: service8,
    mobImage: service8Mob,
  },
  {
    title: "Exploration and Development",
    description: `Al Harouge's range of exploration projects is diverse, encompassing conventional oil and natural gas to deepwater developments. The company strives to achieve production levels without wasting resources, maximizing the potential of mature reservoirs.`,
    description2: `
      The company manages decline rates in existing fields through well workovers, artificial-lift techniques, and facility and equipment improvements, including enhanced-recovery methods such as steam flooding and water injection.`,
    image: service9,
    mobImage: service9Mob,
  },
  {
    title: "Accommodation and Emergency support",
    description: `Al Harouge’s major growth is a direct result of increased activity in the accommodation sector. With a rising demand for quality, cost-effective accommodation, Al Harouge facilitates streamlined and effective manufacturing of a wide range of custom-designed workforce housing and fully self-supporting personnel camps.
   
    `,
    description2:
      "Our high-quality accommodation modules provide housing for both men and women working in some of the harshest and most remote conditions in the industry.The company is supported by a 24-hour emergency support network, ensuring that safety and support are never compromised.",
    image: service10,
    mobImage: service10Mob,
  },
];
