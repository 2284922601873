import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import home1 from "../../assets/images/Home/home1.jpg";
import home2 from "../../assets/images/Home/home2.jpg";
import home3 from "../../assets/images/Home/home3.jpg";
import home4 from "../../assets/images/Home/home4.jpg";
import home5 from "../../assets/images/Home/home5.jpg";

import home1_mob from "../../assets/images/Home/home1_mob.jpg";
import home2_mob from "../../assets/images/Home/home2_mob.jpg";
import home3_mob from "../../assets/images/Home/home3_mob.jpg";
import home4_mob from "../../assets/images/Home/home4_mob.jpg";
import home5_mob from "../../assets/images/Home/home5_mob.jpg";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./styles.css";

const Carousel = ({ handleImageLoad }) => {
  const sliderRef = useRef(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const sliderInterval = setInterval(() => {
      sliderRef.current.slickNext();
    }, 4000); // Slide every 5 seconds

    return () => {
      clearInterval(sliderInterval);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0",
    variableWidth: false,
    // nextArrow: true,
  };

  const slides = [
    {
      image: isSmallScreen ? home1_mob : home1,
    },
    {
      image: isSmallScreen ? home2_mob : home2,
    },
    {
      image: isSmallScreen ? home3_mob : home3,
    },
    {
      image: isSmallScreen ? home4_mob : home4,
    },
    {
      image: isSmallScreen ? home5_mob : home5,
    },
  ];

  return (
    <div style={{ overflow: "hidden" }}>
      <Slider ref={sliderRef} {...settings}>
        {slides.map((slide, index) => (
          <div key={index}>
            <img
              onLoad={handleImageLoad}
              alt={slide.title}
              src={slide.image}
              style={{
                width: "100%",
              }}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
