import { Grid, Typography } from "@mui/material";

const ImageTextPercentage = ({ item }) => {
  return (
    <Grid container style={{ marginBottom: 20 }} spacing={10}>
      <Grid item xs={2}>
        <Typography
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            border: "2px solid #ddd",
            fontFamily: "Montserrat",
            color: "white",
          }}
          sx={{
            width: { xs: 60, md: 70, lg: 90 },
            height: { xs: 60, md: 70, lg: 90 },
            fontSize: { xs: 20, md: 25, lg: 30 },
          }}
        >
          {item.percentage}
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography
          style={{
            fontFamily: "Montserrat",
            weight: 500,
            color: "white",
            display: "flex",
          }}
          sx={{ fontSize: { xs: 20, md: 25, lg: 30 } }}
        >
          {item.title}
        </Typography>
        <Typography
          style={{
            fontFamily: "Montserrat",
            weight: 400,
            color: "white",
            textAlign: "start",
          }}
          sx={{ fontSize: { xs: 14, md: 15, lg: 16 } }}
        >
          {item.description}
        </Typography>
      </Grid>
    </Grid>
  );
};
export default ImageTextPercentage;
