import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

const Service = ({ service, isSmallScreen }) => {
  return (
    <Card
      sx={{
        maxWidth: 400,
        height: 500,
        boxShadow: "none",
        border: "1px solid #ddd",
      }}
    >
      <CardMedia sx={{ height: 270 }} image={service.image} />
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          style={{
            display: "flex",
            justifyContent: !isSmallScreen && "center",
            fontFamily: "Montserrat",
            fontWeight: 600,
            fontSize: "15px",
            lineHeight: "30px",
            paddingBottom: isSmallScreen ? 0 : 15,
          }}
        >
          {service.title}
        </Typography>
        <Typography
          style={{
            display: "flex",
            textAlign: !isSmallScreen && "center",
            fontFamily: "Montserrat",
            fontWeight: 400,
            fontSize: "15px",
            lineHeight: "18.29px",
          }}
        >
          {service.forHome || service.description2}
        </Typography>
      </CardContent>
    </Card>
  );
};
export default Service;
