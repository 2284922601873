import { Box, Typography } from "@mui/material";
import HomeText from "../../../../assets/images/Home/HomeText.jpg";
import HomeTextMob from "../../../../assets/images/Home/HomeText_mob.jpg";

const TextOverImage = () => {
  return (
    <>
      <Box
        style={{ position: "relative", textAlign: "center" }}
        sx={{ display: { xs: "none", sm: "flex" } }}
      >
        <img
          src={HomeText}
          alt="global leader"
          style={{
            width: "100%",
          }}
        />
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#27272C4D",
            padding: "40px 40px 40px",
            display: "flex",
            textAlign: "center",
          }}
        >
          <Typography
            style={{
              color: "white",
              fontSize: "30px",
              fontFamily: "Montserrat",
              fontWeight: 600,
              width: 1100,
            }}
          >
            We stand as a global leader in engineering services, specializing in
            catering and camp maintenance, oil field and specialist equipment
            and operation of intricate infrastructures and spanning both
            offshore and onshore domains
          </Typography>
        </div>
      </Box>
      <Box
        style={{ position: "relative", textAlign: "center" }}
        sx={{ display: { sm: "none" } }}
      >
        <img
          src={HomeTextMob}
          alt="global leader"
          style={{
            width: "100%",
          }}
        />
      </Box>
    </>
  );
};
export default TextOverImage;
