import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import Layout from "./commonComponents/Layout/Layout";
import Home from "./pages/Home";
import ContactUs from "./pages/Contact-us";
import AboutUs from "./pages/About-us";
import Services from "./pages/Services";

const App = () => {
  return (
    <MuiThemeProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/services" element={<Services />} />
          </Route>
        </Routes>
      </Router>
    </MuiThemeProvider>
  );
};

export default App;
