import Main from "./Components/Main/Main";
import OurService from "./Components/OurService";
import ServicesList from "./Components/SevicesList/ServicesList";
const Services = () => {
  return (
    <>
      <Main />
      <OurService />
      <ServicesList />
    </>
  );
};
export default Services;
