import icon1 from "../../assets/images/Contact/icon1.png";
import icon2 from "../../assets/images/Contact/icon2.png";
import icon3 from "../../assets/images/Contact/icon3.png";
import icon4 from "../../assets/images/Contact/icon4.png";

export const contacts = [
  { image: icon1, text: "info@elharougegroup.com" },
  { image: icon2, text: "+218 (21) 4837520/22/23 " },
  { image: icon3, text: "+218 (21) 4837521 " },
  {
    image: icon4,
    text: "1st ST. Back of Kia Motors Gurguerish, Abou Nouas Tripoli, Libya",
  },
];
