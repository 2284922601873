import { Box, Divider, Grid, Typography } from "@mui/material";
import logo1 from "../../../../assets/images/Clients/client1.png";
import logo2 from "../../../../assets/images/Clients/client2.png";
import logo3 from "../../../../assets/images/Clients/client3.png";
import logo4 from "../../../../assets/images/Clients/client4.png";
import logo5 from "../../../../assets/images/Clients/client5.png";
import logo6 from "../../../../assets/images/Clients/client6.png";
import logo7 from "../../../../assets/images/Clients/client7.png";
import logo8 from "../../../../assets/images/Clients/client8.png";
import logo9 from "../../../../assets/images/Clients/client9.png";
import logo10 from "../../../../assets/images/Clients/client10.png";

const logos = [
  { image: logo1, width: "" },
  { image: logo2, width: "" },
  { image: logo3, width: "" },
  { image: logo7, width: "" },
  { image: logo6, width: "60%" },
  { image: logo5, width: "60%" },
  { image: logo8, width: "" },
  { image: logo10, width: "" },
  { image: logo4, width: "60%" },
  { image: logo9, width: "60%" },
];
const Clients = () => {
  return (
    <Box
      style={{
        paddingTop: 20,
      }}
      sx={{
        paddingBottom: { xs: 2, sm: 10 },
        paddingLeft: { xs: 3, sm: 5 },
        margin: { xs: 2, sm: 7 },
      }}
    >
      <Divider
        style={{
          backgroundColor: "#27272C",
          width: 55,
          padding: 1,
          marginTop: 20,
          marginBottom: 20,
        }}
      />
      <Typography
        style={{
          color: "#27272C",
          fontFamily: "Montserrat",
          fontWeight: 700,
          fontSize: "30px",
          paddingBottom: 25,
        }}
      >
        Some of our clients{" "}
      </Typography>

      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        {logos.map((logo, index) => {
          return (
            <Grid item xs={6} sm={2.4}>
              <img
                src={logo.image}
                index={index}
                style={{ width: logo.width || "100%" }}
                alt={index}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
export default Clients;
