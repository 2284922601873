import Carousel from "../../commonComponents/Carousel/Carousel";
import About from "./Components/About/About";
import Clients from "./Components/Clients/Clients";
import Services from "./Components/Services";
import TextOverImage from "./Components/TextOverImage";

const Home = () => {
  return (
    <>
      <Carousel />
      <About />
      <Services />
      <TextOverImage />
      <Clients />
    </>
  );
};
export default Home;
