import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CheckIcon from "@mui/icons-material/Check";
const OurService = () => {
  return (
    <Box
      style={{
        paddingTop: 20,
      }}
      sx={{
        paddingBottom: { xs: 2, sm: 10 },
        paddingLeft: { xs: 3, sm: 5 },
        margin: { xs: 2, sm: 7 },
      }}
    >
      <Divider
        style={{
          backgroundColor: "#27272C",
          width: 55,
          padding: 1,
          marginTop: 20,
          marginBottom: 20,
        }}
      />
      <Typography
        style={{
          color: "#27272C",
          fontFamily: "Montserrat",
          fontWeight: 700,
          fontSize: "30px",
          paddingBottom: 25,
        }}
      >
        Our Services
      </Typography>
      <Typography
        style={{
          color: "#000000",
          fontFamily: "Montserrat",
          fontWeight: 500,
          fontSize: "23px",
          marginBottom: 20,
        }}
      >
        For more than 28 years, we've been catering to diverse tastes and
        serving essential energy solutions, consistently delivering exceptional
        services to meet the distinct needs of clients globally in both the
        culinary and oil field realms
      </Typography>
      <Typography
        style={{
          color: "#00000099",
          fontFamily: "Montserrat",
          fontWeight: 400,
          fontSize: "20px",
          marginBottom: 30,
        }}
      >
        <CheckIcon style={{ color: "black", marginRight: 10 }} /> We offer the
        oil and gas sector dependable, cost-effective, and integrated services
        to meet their operational needs
      </Typography>{" "}
      <Typography
        style={{
          color: "#00000099",
          fontFamily: "Montserrat",
          fontWeight: 400,
          fontSize: "20px",
        }}
      >
        <CheckIcon style={{ color: "black", marginRight: 10 }} />
        Our objective is to exceed client expectations by going above and beyond
        to address their needs and fulfill their requirements
      </Typography>
    </Box>
  );
};
export default OurService;
