import { Box, Grid, Typography } from "@mui/material";
import Service from "../../../../assets/images/Services/ServiceMain.png";
import ServiceMob from "../../../../assets/images/Services/ServiceMainMob.png";
import ImageTextPercentage from "./Components/imageTextPercentage/ImageTextPercentage";

const Main = () => {
  return (
    <>
      <Box
        style={{ position: "relative", textAlign: "center" }}
        sx={{ display: { xs: "none", sm: "flex" } }}
      >
        <img
          src={Service}
          alt="global leader"
          style={{
            width: "100%",
          }}
        />
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#27272C4D",
            padding: "40px 40px 40px",
            display: "flex",
            textAlign: "center",
            width: "80%",
          }}
        >
          <Grid container>
            <Grid
              item
              xs={6}
              md={5}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  color: "white",
                  fontSize: "30px",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                }}
              >
                Powerful Solutions for a sustainable future
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ImageTextPercentage
                item={{
                  percentage: "98%",
                  title: "Successful Projects ",
                  description:
                    "Our portfolio boasts a series of successful projects in oil field services",
                }}
              />
              <ImageTextPercentage
                item={{
                  percentage: "96%",
                  title: "Satisfied clients",
                  description:
                    "Our commitment to excellence in oil field services is reflected in our satisfied clients",
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        style={{ position: "relative", textAlign: "center" }}
        sx={{ display: { sm: "none" } }}
      >
        <img
          src={ServiceMob}
          alt="global leader"
          style={{
            width: "100%",
          }}
        />
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "5%",
            transform: "translate(-5%, -50%)",
            backgroundColor: "#27272C4D",
            display: "flex",
            textAlign: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            style={{
              color: "white",
              fontSize: "25px",
              fontFamily: "Montserrat",
              fontWeight: 600,
              display: "flex",
              textAlign: "start",
              marginBottom: 50,
              marginLeft: 10,
            }}
          >
            Powerful Solutions for a sustainable future
          </Typography>
          <ImageTextPercentage
            item={{
              percentage: "98%",
              title: "Successful Projects ",
              description:
                "Our portfolio boasts a series of successful projects in oil field services",
            }}
          />

          <ImageTextPercentage
            item={{
              percentage: "96%",
              title: "Satisfied clients",
              description:
                "Our commitment to excellence in oil field services is reflected in our satisfied clients",
            }}
          />
        </div>
      </Box>
    </>
  );
};
export default Main;
