import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import AboutUsMain from "../../assets/images/Aboutus/Aboutus.jpg";
import AboutUsMainMob from "../../assets/images/Aboutus/AboutusMob.jpg";

import Partners from "./Components/Partners";
const AboutUs = () => {
  return (
    <>
      <Box
        style={{ position: "relative", textAlign: "center" }}
        sx={{ display: { xs: "none", sm: "flex" } }}
      >
        <img
          src={AboutUsMain}
          alt="global leader"
          style={{
            width: "100%",
          }}
        />
        <Box
          style={{
            position: "absolute",

            backgroundColor: "#27272C4D",
            display: "flex",
            textAlign: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Box
            style={{
              position: "absolute",

              display: "flex",
              textAlign: "start",
              top: "70%",
              transform: "translate(-50%, -50%)",
              flexDirection: "column",
              borderLeft: "6px solid white",
            }}
            sx={{
              width: { xs: "60%", md: "90%", lg: "40%" },
              left: { xs: "40%", md: "30%", lg: "25%" },
            }}
          >
            <Divider
              style={{
                backgroundColor: "white",
                width: 160,
                padding: 2,
                marginBottom: 10,
              }}
            />
            <Typography
              style={{
                paddingLeft: 10,
                fontFamily: "Montserrat",
                fontSize: "35px",
                fontWeight: 500,
                color: "#FFFFFF",
              }}
            >
              Navigating a fresh path toward a more radiant future.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        style={{ position: "relative", textAlign: "center" }}
        sx={{ display: { sm: "none" } }}
      >
        <img
          src={AboutUsMainMob}
          alt="global leader"
          style={{
            width: "100%",
          }}
        />
      </Box>
      <Box
        style={{
          paddingTop: 20,
        }}
        sx={{
          paddingBottom: { xs: 2, sm: 10 },
          paddingLeft: { xs: 3, sm: 5 },
          margin: { xs: 2, sm: 7 },
        }}
      >
        <Divider
          style={{
            backgroundColor: "#27272C",
            width: 55,
            padding: 1,
            marginTop: 20,
            marginBottom: 20,
          }}
        />
        <Typography
          style={{
            color: "#27272C",
            fontFamily: "Montserrat",
            fontWeight: 700,
            fontSize: "30px",
            paddingBottom: 25,
          }}
        >
          About us{" "}
        </Typography>
        <Typography
          style={{
            color: "#27272C",
            fontFamily: "Montserrat",
            fontWeight: 500,
            fontSize: "25px",
            whiteSpace: "pre-line",
          }}
        >
          {`Founded in 1995, Al Harouge Catering & Oilfield Services has emerged as a stalwart provider of reliable, cost-effective, and comprehensive services tailored to the dynamic needs of the oil and gas industry. In the course of the past decade, our company has undergone remarkable growth, boasting a workforce of over 600 skilled professionals. Our client portfolio spans global, European, and local entities operating within the vibrant landscape of Libya.
          
          
          At Al Harouge, our overarching mission is to surpass customer expectations, epitomized by our unwavering commitment to going the extra mile in meeting their unique requirements. This dedication has been pivotal to our sustained success in the industry.
          
          
          Central to our ethos is a steadfast commitment to delivering quality products and exceptional services. We achieve this through the integration of cutting-edge technology and a team of dependable personnel, ensuring consistently high levels of customer satisfaction. At Al Harouge, we stand poised to meet and exceed the evolving demands of the oil and gas sector, driven by our enduring pursuit of excellence.

          `}
        </Typography>
      </Box>
      <Partners />
    </>
  );
};
export default AboutUs;
