import { Box, Typography, Grid, Button, Divider } from "@mui/material";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { useNavigate } from "react-router";

const About = () => {
  const navigate = useNavigate();
  return (
    <Box
      style={{
        backgroundColor: "#D2D2D233",
        paddingTop: 20,
      }}
      sx={{
        paddingBottom: { xs: 2, sm: 10 },
        paddingLeft: { xs: 3, sm: 5 },
        margin: { xs: 2, sm: 7 },
      }}
    >
      <Divider
        style={{
          backgroundColor: "#27272C",
          width: 55,
          padding: 1,
          marginTop: 20,
          marginBottom: 20,
        }}
      />
      <Typography
        style={{
          color: "#27272C",
          fontFamily: "Montserrat",
          fontWeight: 700,
          fontSize: "30px",
          paddingBottom: 25,
        }}
      >
        Who we are?
      </Typography>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={8.5}>
          <Typography
            style={{
              color: "#00000099",
              fontFamily: "Montserrat",
              fontWeight: 500,
              fontSize: "20px",
            }}
          >
            Harouge International Group is committed to delivering superior
            products and services through advanced technology, stringent safety
            protocols, and a highly skilled workforce. Our logistics efficiency
            ensures seamless operations, consistently exceeding clients
            expectations while upholding the highest standards of
            professionalism.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3.5}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            style={{
              backgroundColor: "white",
              fontFamily: "Montserrat",
              color: "#27272C",
              padding: "16px 70px",
              fontSize: "17px",
              fontWeight: 600,
            }}
            onClick={() => navigate("/about-us")}
          >
            READ MORE{" "}
            <ArrowCircleRightOutlinedIcon style={{ marginLeft: 15 }} />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
export default About;
