import { Divider, Grid, Typography } from "@mui/material";
import { services as servicesList } from "../../../Home/Components/Services/constant";
const ServicesList = () => {
  return servicesList.map((service) => {
    return (
      <>
        <Grid
          container
          spacing={4}
          style={{ marginBottom: 70 }}
          sx={{ pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}
        >
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: { xs: "none", sm: "flex" } }}
          >
            <img
              src={service.image}
              style={{ width: "100%" }}
              alt={service.title}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ display: { sm: "none" } }}>
            <img
              src={service.mobImage}
              style={{ width: "100%" }}
              alt={service.title}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "start",
              justifyContent: "center",
            }}
          >
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontSize: "25px",
                fontWeight: 600,
                marginBottom: 5,
              }}
            >
              {service.title}
            </Typography>
            <Divider
              style={{
                backgroundColor: "#27272C",
                width: 65,
                padding: 1,
                marginBottom: 20,
              }}
              sx={{ display: { sm: "none" } }}
            />
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontSize: "20px",
                fontWeight: 400,
                whiteSpace: "pre-line",
              }}
            >
              {service.description}
            </Typography>
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontSize: "20px",
                fontWeight: 400,
                whiteSpace: "pre-line",
              }}
            >
              {service.description2}
            </Typography>
          </Grid>
        </Grid>
        <Divider style={{ marginBottom: 70 }} />
      </>
    );
  });
};
export default ServicesList;
