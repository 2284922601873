import { Grid, Typography } from "@mui/material";

const IconWithText = ({ item }) => {
  return (
    <Grid container style={{ paddingBottom: 15 }}>
      <Grid item xs={2}>
        <img src={item.image} alt="" />
      </Grid>
      <Grid item xs={6}>
        <Typography
          style={{
            color: "#27272C",
            fontFamily: "Montserrat",
            fontWeight: 500,
            fontSize: "15",
          }}
        >
          {item.text}
        </Typography>
      </Grid>
    </Grid>
  );
};
export default IconWithText;
