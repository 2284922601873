import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import map from "../../assets/images/Contact/map.png";
import mapMob from "../../assets/images/Contact/mapMob.png";
import IconWithText from "./Components/IconWithText/IconWithText";
import { contacts } from "./constant";

const ContactUs = () => {
  return (
    <Box style={{ position: "relative" }}>
      <Box sx={{ display: { xs: "none", md: "flex" } }}>
        <img
          src={map}
          alt="global leader"
          style={{
            width: "100%",
          }}
        />
      </Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
        }}
        sx={{
          position: { sm: "absolute" },
          width: { sm: "50%", lg: "30%" },
          top: { xs: "10%", lg: "13%" },
          left: { xs: "0%", sm: "5%", lg: "7%" },
          padding: { xs: 2, md: 5 },
          backgroundColor: { sm: "#27272C4D" },
        }}
      >
        <Divider
          style={{
            backgroundColor: "#27272C",
            width: 55,
            padding: 1,
            marginTop: 20,
            marginBottom: 20,
          }}
        />
        <Typography
          style={{
            color: "#27272C",
            fontFamily: "Montserrat",
            fontWeight: 700,
            fontSize: "30px",
            paddingBottom: 25,
          }}
        >
          Contact us{" "}
        </Typography>
        <Typography
          style={{
            color: "#27272C",
            fontFamily: "Montserrat",
            fontWeight: 400,
            fontSize: "20px",
            paddingBottom: 35,
          }}
        >
          For assistance with your current or upcoming projects, reach out to us
          at{" "}
          <p style={{ fontWeight: 600, display: "inline" }}>
            info@elharougegroup.com{" "}
          </p>
          . Our team is ready to support you, and one of our sales or support
          representatives will be in touch with you shortly to address your
          needs
        </Typography>
        {contacts.map((contact) => {
          return <IconWithText item={contact} />;
        })}
      </Box>
      <Box sx={{ display: { md: "none" } }}>
        <img
          src={mapMob}
          alt="global leader"
          style={{
            width: "100%",
          }}
        />
      </Box>
    </Box>
  );
};
export default ContactUs;
