import { Box, Divider, Grid, Typography } from "@mui/material";
import logo1 from "../../../../assets/images/Partners/partner1.png";
import logo2 from "../../../../assets/images/Partners/partner2.png";
import logo3 from "../../../../assets/images/Partners/partner3.png";
import logo4 from "../../../../assets/images/Partners/partner4.png";
import logo5 from "../../../../assets/images/Partners/partner5.png";
import logo6 from "../../../../assets/images/Partners/partner6.png";

const logos = [
  { image: logo1, width: "" },

  { image: logo3, width: "" },
  { image: logo4, width: "70%" },
  { image: logo5, width: "80%" },
  { image: logo6, width: "60%" },
  { image: logo2, width: "80%" },
];
const Partners = () => {
  return (
    <Box
      sx={{
        paddingBottom: { xs: 2, sm: 10 },
        paddingLeft: { xs: 3, sm: 5 },
        margin: { xs: 2, sm: 7 },
      }}
    >
      <Divider
        style={{
          backgroundColor: "#27272C",
          width: 55,
          padding: 1,
          marginBottom: 20,
        }}
      />
      <Typography
        style={{
          color: "#27272C",
          fontFamily: "Montserrat",
          fontWeight: 700,
          fontSize: "30px",
          paddingBottom: 25,
        }}
      >
        Some of our partners
      </Typography>

      <Grid
        container
        spacing={3}
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        {logos.map((logo, index) => {
          return (
            <Grid item xs={6} sm={4} md={2}>
              <img
                src={logo.image}
                index={index}
                style={{ width: logo.width || "100%" }}
                alt={index}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
export default Partners;
