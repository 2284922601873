import { useCallback, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import logo from "../../assets/images/logo.png";
import { Divider, Drawer, ListItem, ListItemText } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const pages = [
  { title: "Home", navigateTo: "/" },
  { title: "Our Services", navigateTo: "/services" },
  { title: "About us", navigateTo: "/about-us" },
  { title: "Contact", navigateTo: "/contact-us" },
];

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: "400",
  },
  icons: {
    color: "#282828",
    width: "30px",
    height: "33px",
  },
}));

const Navbar = () => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDrawer = useCallback(() => {
    setDrawerOpen(!drawerOpen);
  }, [setDrawerOpen, drawerOpen]);

  const onDrawerClick = useCallback(
    (page) => {
      toggleDrawer();
      navigate(page.navigateTo);
    },
    [toggleDrawer, navigate]
  );

  return (
    <AppBar
      position="static"
      style={{ backgroundColor: "transparent", boxShadow: "none" }}
    >
      <Container maxWidth="xl">
        <Toolbar>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              width: "20%",
              cursor: "pointer",
            }}
            onClick={() => navigate("/")}
          >
            <img
              alt="home"
              src={logo}
              style={{
                width: "100%",
                height: "auto",
              }}
            />
          </Box>

          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={toggleDrawer}
              color="black"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              width: "60%",
              cursor: "pointer",
              marginLeft: 5,
            }}
            onClick={() => navigate("/")}
          >
            <img
              alt="home"
              src={logo}
              style={{
                width: "100%",
                height: "auto",
              }}
            />
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "flex-end",
              flexDirection: "row",
              columnGap: 10,
            }}
          >
            {pages.map((page) => (
              <Button
                key={page}
                onClick={() => navigate(page.navigateTo)}
                sx={{ my: 2, color: "white", display: "block" }}
                style={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: "#282828",
                }}
              >
                {page.title}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>

      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer}
        className={classes.drawer}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: "80%",
          },
        }}
      >
        <Box
          sx={{
            width: "80%",
            cursor: "pointer",
            my: 2,
            ml: 3,
          }}
          onClick={() => navigate("/")}
        >
          <img
            alt="home"
            src={logo}
            style={{
              width: "100%",
              height: "auto",
              display: "flex",
              alignItems: "center",
            }}
          />
        </Box>
        <Divider />
        {pages.map((page) => (
          <ListItem button onClick={() => onDrawerClick(page)}>
            <ListItemText
              primaryTypographyProps={{
                style: {
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  fontSize: 20,
                  color: "#282828",
                },
              }}
              primary={page.title}
            />
          </ListItem>
        ))}
        <Divider />
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            columnGap: 10,
            paddingLeft: 15,
            paddingTop: 10,
          }}
        >
          <LinkedInIcon style={{ color: "black" }} />
          <InstagramIcon style={{ color: "black" }} />
          <FacebookRoundedIcon style={{ color: "black" }} />
          <TwitterIcon style={{ color: "black" }} />
        </Box>
      </Drawer>
    </AppBar>
  );
};
export default Navbar;
